






















































import { Component, Vue } from 'vue-property-decorator';
import { union } from 'lodash';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import Footer from '@/components/Footer/Footer.vue';
import MyPerformanceRedaction from '@/components/MyPerformanceRedaction/MyPerformanceRedaction.vue';
import MyPerformanceRedactionLoading from '@/components/MyPerformanceRedaction/MyPerformanceRedactionLoading.vue';
import ModalSimulationResultLoading from '@/components/Modal/ModalSimulationResultLoading/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ContainerQuestionResult from './components/ContainerQuestionResult/ContainerQuestionResult.vue';
import ContainerQuestionResultLoading from './components/ContainerQuestionResult/ContainerQuestionResultLoading.vue';
import MyResult from './components/MyResult/MyResult.vue';
import MyResultLoading from './components/MyResult/MyResultLoading.vue';

import ConfigSidebar from '../../mixins/ConfigSidebar';

import SimulationService from '@/services/Simulation/SimulationService';

import { SIMULATED_CONSTANT } from '../../constants/SimulatedResult';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import ActiveModal from '@/share/Util/ActiveModal';

import {
  NewStructureQuestionsResult as INewStructureQuestionsResult,
  NewQuestionResult as INewQuestionResult,
  QuestionResult as IQuestionResult,
  OptionResult as IOptionResult,
  SimulatedDayResult as ISimulatedDayResult,
} from '../../interface/ISimulated';

const TIME_INTERVAL_TEN_SECONDS = 10000;
const NUMBER_ATTEMPTS_GET_RESULT = 5;

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Tabs,
    MyResult,
    MyResultLoading,
    MyPerformanceRedaction,
    MyPerformanceRedactionLoading,
    ContainerQuestionResult,
    ContainerQuestionResultLoading,
    Footer,
    ModalSimulationResultLoading,
    FeedbackUser,
  },
  mixins: [ConfigSidebar],
})
export default class SimulatedResult extends Vue {
  private tabActive = SIMULATED_CONSTANT.TAB_ACTIVE_LANGUAGE;
  private timeIntervalGetResultId = 0;
  private timeoutGetResultId = 0;
  private currentAttemptToGetResult = 1;
  private successfulResponseStatus = false;
  private isLoadingSimulatedResult = false;
  private newQuestions: Record<string, any> = {};

  private tabMenu: Array<TabsMenuInterface> = [];

  private rightAndWrongQuestions: Record<string, any> = {
    lc_lt: { totalCorrect: 0, totalWrong: 0 },
    ch_ct: { totalCorrect: 0, totalWrong: 0 },
    cn_ct: { totalCorrect: 0, totalWrong: 0 },
    mt: { totalCorrect: 0, totalWrong: 0 },
  }

  private ServiceSimulation= new SimulationService();
  private setModal = new ActiveModal();

  created() {
    this.setBreadCrumbs();
  }

  async mounted() {
    await this.getSimulatedResult();
  }

  beforeDestroy() {
    this.finalizeProgress();
    this.storeCommitsDestroy();
  }

  get topId() {
    return this.$store.getters.infoSimulatedLog.topId.topID;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get redaction() {
    const { redaction } = this.$store.getters.infoSimulatedResult?.simulations[0];

    if (!redaction) return {};

    const redactionCorrected = redaction?.data;

    if (redactionCorrected) {
      return {
        id: redactionCorrected?.id,
        theme: redactionCorrected?.theme?.title,
        score: redactionCorrected?.score,
        status: redactionCorrected?.status,
        rejection_cause: redactionCorrected.rejection_cause,
      };
    }

    return {
      id: redaction?.id,
      theme: redaction?.theme?.title,
      score: redaction?.score,
      status: redaction?.status,
    };
  }

  get simulatedResultPercentageProgress() {
    return this.$store.getters.simulatedResultPercentageProgress;
  }

  get showContent() {
    return this.isLoadingSimulatedResult || this.successfulResponseStatus;
  }

  nextTab() {
    const indexNextTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive) + SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexNextTab);

    if (indexNextTab !== -1) this.handleActiveTabSwitching(indexNextTab, this.tabMenu);
  }

  previousTab() {
    const indexPreviousTab = this.tabMenu.findIndex((tab: Record<string, any>) => tab.id === this.tabActive) - SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexPreviousTab);

    if (indexPreviousTab !== -1) this.handleActiveTabSwitching(indexPreviousTab, this.tabMenu);
  }

  setCurrentlyActiveTab(indexTab: number) {
    this.tabMenu = this.tabMenu.map((tab: TabsMenuInterface, index: number) => {
      if (index === indexTab) return { ...tab, active: true };
      return { ...tab, active: false };
    });
  }

  handleActiveTabSwitching(indexTabSelected: number, tabMenuUpdated: Array<TabsMenuInterface>) {
    this.tabActive = tabMenuUpdated[indexTabSelected].id || '';
    this.tabMenu = tabMenuUpdated;
  }

  getRightAndWrongQuestions() {
    if (!this.newQuestions) return;

    Object.keys(this.newQuestions)
      .forEach((key: string) => {
        this.newQuestions[key].forEach((question: INewQuestionResult) => {
          if (question.userOptionID === question.correctOptionID) {
            this.rightAndWrongQuestions[key].totalCorrect += 1;
            return;
          }

          this.rightAndWrongQuestions[key].totalWrong += 1;
        });
      });

    this.$store.commit('saveRightAndWrongQuestions', this.rightAndWrongQuestions);
  }

  storeCommitsDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('saveInfoSimulatedResult', {});
    this.$store.commit('saveSimulatedQuestionsResult', {});
    this.$store.commit('saveSimulatedTitleCover', {});
    this.$store.commit('saveInfoSimulationLog', {});
    this.$store.commit('saveRightAndWrongQuestions', {});
    this.$store.commit('setSimulatedResultPercentageProgress', 0);
  }

  async getSimulatedResult() {
    this.showModalSimulationResultLoading();
    await this.getResultWithProgress();
  }

  async getResultWithProgress() {
    const incrementValue = Math.ceil(100 / NUMBER_ATTEMPTS_GET_RESULT);
    const increment = this.simulatedResultPercentageProgress + incrementValue;

    this.currentAttemptToGetResult += 1;

    this.incrementResultPercentegeProgress(increment);
    await this.getResult();

    if (
      !this.successfulResponseStatus
      && this.currentAttemptToGetResult <= NUMBER_ATTEMPTS_GET_RESULT
    ) {
      this.timeoutGetResultId = setTimeout(this.getResultWithProgress, TIME_INTERVAL_TEN_SECONDS);
      return;
    }

    this.finalizeProgress();
    this.getRightAndWrongQuestions();
  }

  async getResult() {
    try {
      this.isLoadingSimulatedResult = true;

      const response = await this.ServiceSimulation.getResultSimulated(this.topId);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.successfulResponseStatus = true;
        this.isLoadingSimulatedResult = false;

        if (response?.data?.simulations.length) {
          this.createNewStructureSimulated(response?.data?.simulations);
        }
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar resultado do simulado.',
        status: 'error',
      });
    }
  }

  finalizeProgress() {
    clearTimeout(this.timeoutGetResultId);
    this.incrementResultPercentegeProgress(100);
    this.disableModalSimulationResultLoading();
    this.isLoadingSimulatedResult = false;
  }

  incrementResultPercentegeProgress(newPercent: number) {
    this.$store.commit('setSimulatedResultPercentageProgress', newPercent);
  }

  createNewStructureSimulated(simulated: Array<ISimulatedDayResult>) {
    const listQuestionsSeparatedByDiscipline = simulated.reduce((acc: Array<Array<IQuestionResult>>, simulationDay: ISimulatedDayResult) => union(acc, Object.keys(simulationDay.questions).map((key: string) => (simulationDay.questions[key]))), []);

    this.separateQuestionsByArea(listQuestionsSeparatedByDiscipline);
  }

  separateQuestionsByArea(listQuestionsResult: Array<Array<IQuestionResult>>) {
    this.newQuestions = listQuestionsResult.reduce((acc: INewStructureQuestionsResult, discipline: Array<IQuestionResult>) => {
      discipline.map((question: IQuestionResult) => (
        {
          ...question,
          id: question.questionID,
          question: question.questionContent,
          response: question.userOptionID ? 'answered' : 'skip',
          resolution: question.resolutionContent,
          options: this.setNewListOptions(question),
        }))
        .forEach((question: INewQuestionResult) => {
          if (['Língua Portuguesa', 'Literatura', 'Língua Inglesa', 'Língua Espanhola', 'Arte'].includes(question.titleDiscipline)) acc.lc_lt.push(question);

          else if (['História', 'Geografia', 'Filosofia', 'Sociologia'].includes(question.titleDiscipline)) acc.ch_ct.push(question);

          else if (['Química', 'Física', 'Biologia'].includes(question.titleDiscipline)) acc.cn_ct.push(question);

          else acc.mt.push(question);
        });

      return acc;
    }, {
      lc_lt: [],
      ch_ct: [],
      cn_ct: [],
      mt: [],
    });

    if (this.tabActive === SIMULATED_CONSTANT.TAB_NAME_LANGUAGES) this.filterQuestionsFromSelectedLanguage(this.newQuestions[this.tabActive]);
    this.setTabMenu();
  }

  setTabMenu() {
    const newFilteredTab: Array<TabsMenuInterface> = SIMULATED_CONSTANT.LIST_TAB.filter((tab: TabsMenuInterface) => (tab.id ? this.newQuestions[tab.id].length : ''));

    this.tabMenu = newFilteredTab;
  }

  filterQuestionsFromSelectedLanguage(listQuestion: Array<INewQuestionResult>) {
    const idLanguageSelected = this.$store.getters.infoSimulatedResult.simulations[0].language;

    this.newQuestions[this.tabActive] = listQuestion.filter((question: INewQuestionResult) => (idLanguageSelected === SIMULATED_CONSTANT.ID_LANGUAGE_SPANISH ? question.titleDiscipline !== SIMULATED_CONSTANT.DISCIPLINE_ENGLISH : question.titleDiscipline !== SIMULATED_CONSTANT.DISCIPLINE_SPANISH));
  }

  setNewListOptions(question: IQuestionResult) {
    return question.options.map((option: IOptionResult) => ({
      ...option,
      answerOptionID: question.userOptionID,
      idOptions: option.optionID,
      title: option.optionContent,
      correct: question.correctOptionID === option.optionID ? SIMULATED_CONSTANT.OPTIONS_CORRECT : SIMULATED_CONSTANT.OPTIONS_INCORRECT,
      value: option.optionID === question.userOptionID,
    }));
  }

  goToSimulationSISU() {
    const { id } = this.$route.params;

    this.$router.push({
      name: 'EnemSimulator',
      params: {
        id,
      },
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Simulados', to: '/simulados' },
      { title: 'Resultado', to: null },
    ]);
  }

  showModalSimulationResultLoading() {
    this.setModal.activeModal('ModalSimulationResultLoading');
  }

  disableModalSimulationResultLoading() {
    setTimeout(() => {
      this.setModal.disableModal('ModalSimulationResultLoading');
    }, 1000);
  }
}
