


















































































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading,
  },
})
export default class ContainerQuestionResultLoading extends Vue {
  private quantityLoadingContainer = 2;
  private quantityLoadingQuestion = 3;
  private quantityLoadingMapQuestions = 15;
}
