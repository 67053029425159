





























































import {
  Component, Mixins,
} from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ExerciseContainer from '@/components/Exercise/ExerciseContainer/ExerciseContainer.vue';
import MapQuestions from '@/components/Exercise/MapQuestions/MapQuestions.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import RadioAlternativeResults from '@/components/Exercise/RadioAlternativeResults/RadioAlternativeResults.vue';
import HelpResolution from '@/components/Exercise/HelpResolution/QuestionHelpResolution/index.vue';
import VideoThumb from '@/components/VideoThumb/index.vue';
import NavigationQuestion from '@/components/Exercise/NavigationQuestion/NavigationQuestion.vue';

import ConfigMapQuestions from '../../../../mixins/ConfigMapQuestions';

@Component({
  components: {
    FeedbackUser,
    ExerciseContainer,
    MapQuestions,
    Question,
    HelpResolution,
    VideoThumb,
    RadioAlternativeResults,
    NavigationQuestion,
  },
})
export default class ContainerQuestionResult extends Mixins(ConfigMapQuestions) {

  get listAlternativesQuestion() {
    return this.questionTab?.options;
  }

  get textResolution() {
    return this.questionTab?.resolution;
  }
}
